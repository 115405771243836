/* global TokenExIframeClient */
import { useState, useEffect } from "react";
import { useWizard } from "react-use-wizard";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner"; // Import Spinner component

import SummaryTable from "components/SummaryTable";
import Select from "components/Select";
import { useCheckoutContext } from "context/CheckoutContext";

import ShipMethods from "./exigo/ShipMethods";
import { submitOrder } from "api";
import { API_LOCAL_BASE_URL } from "config";

const Payment = ({ cart }) => {
  const [isValidToken, setValidToken] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isCardValid, setIsCardValid] = useState(true);
  const [orderStatus, setOrderStatus] = useState("invalid");
  const [token, setToken] = useState("");
  const { previousStep, nextStep } = useWizard();
  const { checkout, setCheckout } = useCheckoutContext();
  const [tokenExClient, setTokenExClient] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [monthError, setMonthError] = useState("");
  const [yearError, setYearError] = useState("");
  const [monthValidated, setMonthValidated] = useState(false);
  const [yearValidated, setYearValidated] = useState(false);
  const [nameError, setNameError] = useState("");
  const [nameValidated, setNameValidated] = useState(false);
  const [cvvError, setCvvError] = useState("");
  const [cvvValidated, setCvvValidated] = useState(false);

  let windowWidth = window.innerWidth;
  let screenSize = windowWidth < 576 ? "98%" : "100%";

  useEffect(() => {
    setCheckout({ ...checkout, currentStep: 3 });

    const initializeTokenEx = async () => {
      try {
        const script = document.createElement("script");
        script.src = `${API_LOCAL_BASE_URL}api/Checkout/TokenEx`;
        script.onload = () => {
          // Initialize TokenExIframeClient
          const tokenExClient = new TokenExIframeClient("tokenExIframeDiv", {
            styles: {
              base: `
                display: block;
                width: ${screenSize};
                height: 40px;
                max-height: 40px;
                min-height: 40px;
                padding: .375rem .5rem;
                font-size: 1rem;
                line-height: 1.5;
                color: #212529;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid #CED4DA;
                border-radius: .25rem;
                transition: border-color .15s ease-in-out;
                margin: 0;
                font-family: 'Helvetica Neue';
                overflow: hidden;
                box-sizing: border-box;
              `,
              focus: "color: #12345; border: 2px solid #212529; outline:none;",
              error: "color: #212529; border: 1px solid #dc3545; outline:none;",
            },
            origin: window.location,
            tokenExID: "9691041715912503",
          });
          setTokenExClient(tokenExClient);
        };

        document.head.appendChild(script);
      } catch (error) {
        console.error(error);
      }
    };

    initializeTokenEx();
  }, []);

  useEffect(() => {
    // Set acceptedTerms to true by default
    updateNestedContex("acceptedTerms", true, "billingAddress");
  }, []); // Empty dependency array means this runs once on mount

  const cancelOrder = () => {
    window.location = "https://424543.myshopify.com/collections/shop-all";
  };

  const reviewOrder = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    setValidated(true);

    // Check if credit card is expired
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // JavaScript months are 0-based
    const cardYear = parseInt(checkout.creditcard.expirationYear);
    const cardMonth = parseInt(checkout.creditcard.expirationMonth);

    if (
      cardYear < currentYear ||
      (cardYear === currentYear && cardMonth < currentMonth)
    ) {
      setMonthError("Card has expired");
      setYearError("Card has expired");
      event.stopPropagation();
      return;
    }

    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }

    try {
      setValidToken(true);

      if (tokenExClient && form.checkValidity()) {
        tokenExClient.tokenize({
          success: function (data) {
            setIsCardValid(true);
            setCheckout({
              ...checkout,
              creditcard: {
                ...checkout.creditcard,
              },
            });
            setOrderStatus("valid");
            setToken(data);
          },
          error: function (data) {
            setIsCardValid(false);
            return;
          },
        });
      }
    } catch (error) {
      setIsCardValid(false);
      console.error("Unexpected error during tokenization:", error);
    } finally {
      setValidToken(false);
    }
  };

  const confirmOrder = () => {
    setLoading(true);
    submitOrder(checkout, token)
      .then(async (response) => {
        if (response.status === 200) {
          const data = await response.json();
          if (data.success) {
            if (checkout.cart.customerToken === "null") {
              nextStep();
            } else {
              window.location =
                "https://oolalife.com/account?orderpoints=" +
                checkout.orderPoints +
                "&clearcart=true";
            }
          } else {
            setErrorMessage(
              data.message || "Failed to submit order. Please try again."
            );
          }
        } else if (response.status === 400) {
          // Handle 400 status code
          setErrorMessage(
            "Failed to submit order. Please check your input and try again."
          );
        } else {
          // Handle other status codes
          setErrorMessage("Failed to submit order. Please try again.");
        }
      })
      .catch((error) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateNestedContex = (target, value, key) => {
    let newState = checkout[key];
    newState[target] = value;

    setCheckout({ ...checkout, [key]: newState });
  };

  const {
    creditcard: { name, expirationMonth, expirationYear, cvv },
    billingAddress: {
      sameAsShipping,
      acceptedTerms,
      country,
      state,
      streetAddress,
      city,
      zip,
    },
  } = checkout;

  return (
    <div>
      <div className="wizardContainer">
        <button className="wizardLink">Cart</button>
        {">"}
        <button className="wizardLink">Account Details</button>
        {">"}
        <button className="wizardLink" onClick={() => previousStep()}>
          Shipping Address
        </button>
        {">"}
        <button className="wizardLink" onClick={() => previousStep()}>
          Subscription
        </button>
        {">"}
        <button className="wizardLink">Payment</button>
      </div>

      <SummaryTable
        contact={cart.customer.email}
        referral={cart.cart.referral}
      />

      {orderStatus === "invalid" && (
        <Form noValidate validated={validated} onSubmit={reviewOrder}>
          <Row className="mb-3">
            <ShipMethods shipMethods={cart.cart.shipMethods} />
          </Row>

          <Row className="mb-3">
            <Col md="12">
              <h2 className="marginBottomSmall marginTopSmall">Payment</h2>
              <p>All transactions are encrypted and secure.</p>
              <div className="col-md-8">
                <div className="form-group"></div>
              </div>

              <div className="paymentContainer">
                <Row>
                  <Col xs="12" md="12">
                    <Form.Group className="payment-name-group">
                      <Form.Label className="creditcardLabel">
                        NAME ON CARD
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={name}
                        className={`payment-form ${nameError
                          ? "is-invalid"
                          : nameValidated && !nameError
                            ? "is-valid"
                            : ""
                          }`}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^[A-Za-z\s]*$/.test(value)) {
                            updateNestedContex("name", value, "creditcard");
                            setNameError("");
                          } else {
                            setNameError(
                              "Name should only contain letters and spaces"
                            );
                          }
                        }}
                        onBlur={(e) => {
                          setNameValidated(true);
                          if (!e.target.value.trim()) {
                            setNameError("Name is required");
                          } else if (!/^[A-Za-z\s]+$/.test(e.target.value)) {
                            setNameError(
                              "Name should only contain letters and spaces"
                            );
                          } else {
                            setNameError("");
                          }
                        }}
                        required
                      />
                      {nameError && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {nameError}
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group
                      className={`mb-3 cardnumber-group ${!isCardValid ? "was-validated" : ""}`}
                    >
                      <Form.Label className="creditcardLabel">
                        CARD NUMBER
                      </Form.Label>
                      <div id="tokenExIframeDiv"></div>

                      <div className="position-relative">
                        <input
                          type="hidden"
                          id="Token"
                          name="Token"
                          className={`creditcard-token form-control ${!isCardValid ? "is-invalid" : ""}`}
                        />
                        <input
                          type="hidden"
                          id="Display"
                          name="Display"
                          className="creditcard-display"
                        />
                        {!isCardValid && (
                          <div className="invalid-feedback">
                            <i className="bi bi-exclamation-circle-fill"></i>
                            Invalid Credit Card
                          </div>
                        )}
                      </div>
                    </Form.Group>

                    <Form.Group className="mb-3 expiration-group">
                      <Form.Label className="creditcardLabel">
                        EXPIRATION DATE
                      </Form.Label>
                      <div className="d-flex align-items-center position-relative expiration-date-container">
                        <div
                          className="position-relative"
                          style={{ width: "45%" }}
                        >
                          <Form.Control
                            type="text"
                            value={expirationMonth}
                            placeholder="MM"
                            maxLength={2}
                            className={`payment-form ${monthError
                              ? "is-invalid"
                              : monthValidated && !monthError
                                ? "is-valid"
                                : ""
                              }`}
                            onChange={(e) => {
                              const value = e.target.value.replace(/\D/g, "");
                              if (value.length <= 2) {
                                updateNestedContex(
                                  "expirationMonth",
                                  value,
                                  "creditcard"
                                );
                                if (value) {
                                  const month = parseInt(value);
                                  if (month > 12) {
                                    setMonthError(
                                      "Please enter a valid month (01-12)"
                                    );
                                  } else {
                                    setMonthError("");
                                  }
                                } else {
                                  setMonthError("");
                                }
                              }
                            }}
                            onBlur={(e) => {
                              setMonthValidated(true);
                              const month = parseInt(e.target.value);

                              if (!e.target.value) {
                                setMonthError("Month is required");
                                e.target.setCustomValidity("Month is required");
                              } else if (
                                isNaN(month) ||
                                month < 1 ||
                                month > 12
                              ) {
                                setMonthError(
                                  "Please enter a valid month (01-12)"
                                );
                                e.target.setCustomValidity(
                                  "Please enter a valid month (01-12)"
                                );
                              } else {
                                const formattedMonth = month
                                  .toString()
                                  .padStart(2, "0");
                                updateNestedContex(
                                  "expirationMonth",
                                  formattedMonth,
                                  "creditcard"
                                );
                                setMonthError("");
                                e.target.setCustomValidity("");
                              }
                            }}
                            required
                          />
                          {monthError && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {monthError}
                            </div>
                          )}
                        </div>
                        <span className="slash">/</span>
                        <div
                          className="position-relative"
                          style={{ width: "45%" }}
                        >
                          <Form.Control
                            type="text"
                            placeholder="YYYY"
                            maxLength={4}
                            value={expirationYear}
                            className={`payment-form ${yearError
                              ? "is-invalid"
                              : yearValidated && !yearError
                                ? "is-valid"
                                : ""
                              }`}
                            onChange={(e) => {
                              const value = e.target.value.replace(/\D/g, "");
                              if (value.length <= 4) {
                                updateNestedContex(
                                  "expirationYear",
                                  value,
                                  "creditcard"
                                );
                                if (value.length === 4) {
                                  const year = parseInt(value);
                                  const currentYear = new Date().getFullYear();
                                  const maxYear = currentYear + 20;
                                  if (year < currentYear || year > maxYear) {
                                    setYearError(
                                      `Enter a valid year (${currentYear}-${maxYear})`
                                    );
                                  } else {
                                    setYearError("");
                                  }
                                }
                              }
                            }}
                            onBlur={(e) => {
                              setYearValidated(true);
                              const year = parseInt(e.target.value);
                              const currentYear = new Date().getFullYear();
                              const maxYear = currentYear + 20;

                              if (
                                isNaN(year) ||
                                year < currentYear ||
                                year > maxYear ||
                                e.target.value.length !== 4
                              ) {
                                setYearError(
                                  `Enter a valid year (${currentYear}-${maxYear})`
                                );
                                e.target.setCustomValidity(
                                  `Enter a valid year (${currentYear}-${maxYear})`
                                );
                              } else {
                                setYearError("");
                                e.target.setCustomValidity("");
                              }
                            }}
                            required
                          />
                          {yearError && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {yearError}
                            </div>
                          )}
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-3 cvv-group">
                      <Form.Label className="creditcardLabel">CVV</Form.Label>
                      <Form.Control
                        type="text"
                        value={cvv}
                        maxLength={4}
                        className={`payment-form ${cvvError
                          ? "is-invalid"
                          : cvvValidated && !cvvError
                            ? "is-valid"
                            : ""
                          }`}
                        onChange={(e) => {
                          const value = e.target.value.replace(/\D/g, "");
                          updateNestedContex("cvv", value, "creditcard");
                          if (value.length > 0 && !/^\d{3,4}$/.test(value)) {
                            setCvvError("Enter a valid CVV (3 or 4 digits)");
                          } else {
                            setCvvError("");
                          }
                        }}
                        onBlur={(e) => {
                          setCvvValidated(true);
                          if (!e.target.value) {
                            setCvvError("CVV is required");
                          } else if (!/^\d{3,4}$/.test(e.target.value)) {
                            setCvvError("Enter a valid CVV (3 or 4 digits)");
                          } else {
                            setCvvError("");
                          }
                        }}
                        required
                      />
                      {cvvError && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {cvvError}
                        </div>
                      )}
                    </Form.Group>

                    <Form.Check
                      type="checkbox"
                      label="Billing address same as shipping"
                      className="mb-3"
                      checked={sameAsShipping}
                      onChange={(e) => {
                        updateNestedContex(
                          "sameAsShipping",
                          e.target.checked,
                          "billingAddress"
                        );
                      }}
                    />
                    {!sameAsShipping && (
                      <>
                        <Form.Group className="mb-3">
                          <Form.Label className="creditcardLabel">
                            COUNTRY
                          </Form.Label>
                          <Select
                            name="country"
                            value={country}
                            collection="country"
                            nestedName="billingAddress"
                            updateContext={updateNestedContex}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label className="creditcardLabel">
                            STREET ADDRESS
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={streetAddress}
                            className="payment-form"
                            onChange={(e) =>
                              updateNestedContex(
                                "streetAddress",
                                e.target.value,
                                "billingAddress"
                              )
                            }
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label className="creditcardLabel">
                            STATE
                          </Form.Label>
                          <Select
                            name="state"
                            value={state}
                            className="payment-form-select"
                            collection={
                              country === "CA"
                                ? "caProvince"
                                : country === "AU"
                                  ? "auState"
                                  : "usState"
                            }
                            nestedName="billingAddress"
                            updateContext={updateNestedContex}
                          />
                        </Form.Group>

                        <Row>
                          <Col xs="12" md="6">
                            <Form.Group className="mb-3">
                              <Form.Label className="creditcardLabel">
                                CITY
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={city}
                                className="payment-form"
                                onChange={(e) =>
                                  updateNestedContex(
                                    "city",
                                    e.target.value,
                                    "billingAddress"
                                  )
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col xs="12" md="6">
                            <Form.Group className="mb-3">
                              <Form.Label className="creditcardLabel">
                                ZIPCODE
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={zip}
                                className="payment-form"
                                onChange={(e) =>
                                  updateNestedContex(
                                    "zip",
                                    e.target.value,
                                    "billingAddress"
                                  )
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Col>
                </Row>
                <Form.Check
                  type="checkbox"
                  label={
                    <span>
                      I agree to the{" "}
                      <a
                        href="https://myoola.oolalife.com/Content/docs/CustomerAgreement.pdf"
                        className="oola-main-text-color px-1"
                      >
                        customer terms and conditions
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://myoola.oolalife.com/Content/docs/RefundPolicy.pdf"
                        className="oola-main-text-color px-1"
                      >
                        refund policy
                      </a>
                    </span>
                  }
                  className="mb-3"
                  checked={acceptedTerms}
                  onChange={(e) => {
                    updateNestedContex(
                      "acceptedTerms",
                      e.target.checked,
                      "billingAddress"
                    );
                  }}
                  required
                  defaultChecked={true}
                />
              </div>
            </Col>
          </Row>
        </Form>
      )}

      <div className="flex JCEnd">
        {orderStatus === "valid" ? (
          <>
            <Button
              className="btn-bold secondary-color w-50 m-2"
              variant="danger"
              onClick={cancelOrder}
            >
              Cancel Order
            </Button>
            <Button
              className="btn-bold oola-main-color w-50 m-2"
              variant="success"
              disabled={isLoading}
              onClick={!isLoading ? confirmOrder : null}
            >
              {isLoading ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading..</span>
                </Spinner>
              ) : (
                "Confirm Order"
              )}
            </Button>
          </>
        ) : (
          <Button
            className="btn-bold oola-main-color btn-primary w-50"
            variant="primary"
            onClick={reviewOrder}
            disabled={isValidToken}
          >
            {isValidToken ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mx-3"
                />
                Validating Credit Card...
              </>
            ) : (
              "Review Order"
            )}
          </Button>
        )}
      </div>
      {errorMessage && (
        <p className="mt-4 text-danger text-center">{errorMessage}</p>
      )}
    </div>
  );
};

export default Payment;
